import React, { useEffect } from "react";

import * as S from "./styles";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";

function Header() {
  const { app, loading } = useSelector((state) => state.appReducer);

  useEffect(() => {
    if (!loading) setup();
  }, [loading]);

  function setup() {
    console.log("Header setup");
    window.document.title = app?.title || app?.name;
    setFavicon(app?.favicon);
  }

  function setFavicon(url) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = url;
  }

  return (
    <S.Container
      bgColor={app?.colors?.main}
      textColor={app?.colors?.headerText}
    >
      <Container>
        <Row>
          <Col
            md={3}
            style={{ display: "flex", alignItems: "center", gap: 10 }}
          >
            {app?.logo && (
              <img
                src={app?.logo}
                alt={app?.name}
                style={{ height: 50, width: "auto" }}
              />
            )}
          </Col>
          <Col md={9}>{app?.title}</Col>
        </Row>
      </Container>
    </S.Container>
  );
}

export default Header;
