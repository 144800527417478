import styled from "styled-components";
import COLORS from "../../util/COLORS";

export const Container = styled.div`
  background: ${(props) => props?.backgroundColor || "#f4f5f9"};
  // full width and height
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // centering the container
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // centering the content
  text-align: center;
`;

export const WorkspaceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: ${COLORS.main};
  min-height: 100vh;
`;

export const WorkspaceWhiteContainer = styled.div`
  grid-area: 1 / 2 / 2 / 6;
  display: flex;
  padding: 67px 51px;
  flex-direction: column;
  gap: 14px;
  flex-shrink: 0;
  border-radius: 0px;
  background: #fff;
  align-items: flex-start;
  justify-content: center;
`;

export const WhiteContainer = styled.div`
  display: flex;
  width: 415px;
  padding: 67px 51px;
  flex-direction: column;
  gap: 14px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 6px 52.5px -26px #a8a8a8;
  align-items: center;
  justify-content: center;
`;
