import styled from "styled-components";

export const Container = styled.div`
  padding: 50px;
  margin: 20px;
  background-color: #eaf7ff;
  border-radius: 25px;
  // make it full width
  
  > .pricing-table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    // make it 1 column on mobile
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }


    > div {
      padding: 20px;
      border: 1px solid #ffffff;
      border-radius: 5px;
      text-align: center;
      background-color: #ffffff;
      > h2 {
        color: #333;
        font-size: 1.5em;
        margin-bottom: 10px;
      }
      > p {
        color: #666;
        font-size: 1em;
      }
    }
  }
  > h1 {
    font-size: 1.3em;
    color: #333;
    margin-bottom: 10px;
  }
`;
