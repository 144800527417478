import React from "react";
import { Col, Container, Row } from "reactstrap";
import Header from "../../components/Header";

function KnowledgeBase() {
  return (
    <Container>
      <Header />
      <Row>
        <Col md={12}>
          <h1>KnowledgeBase</h1>
        </Col>
      </Row>
    </Container>
  );
}

export default KnowledgeBase;
