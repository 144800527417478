import React from "react";
import { Button as StyledButton } from "../../routes/main-styles";
import { useSelector } from "react-redux";

function Button({
  children,
  color,
  backgroundColor,
  onClick = () => {
    console.log("Clicou");
  },
}) {
  const { colors } = useSelector((state) => state.appReducer?.app);
  console.log("COLORS:", colors);
  return (
    <StyledButton
      color={color || "#fff"}
      backgroundColor={backgroundColor || colors?.main}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
