import React from 'react';

import * as S from './styles';

function Or({text= "ou"}) {
  return <S.Container>
    <S.Line />
    <S.Text>{text}</S.Text>
    <S.Line />
  </S.Container>;
}

export default Or;