import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APP } from "../../../api/APP";
import { API_URL, MAIN_APP_URL } from "../../../util/constants";

const initialState = {
  loading: true,
  error: false,
  app: {
    name: "HelpDesk.app",
    colors: {
      main: "#950f52",
      secondary: "#555",
      accent: "#777",
      text: "#fff",
      title: "#ffffff",
    },
    logo: "/assets/logo.png",
    favicon: "https://intercom.help/sei-de-cor/assets/favicon",
    website: "https://app.seidecor.com.br/#/v2",
  },
};

// async load app by id
export const loadApp = createAsyncThunk("app/loadApp", async () => {
  // gets id from subdomain
  console.log("Vai carregar o app");

  const id = window.location.hostname.split(".")[0];
  console.log("ID:", id);

  const response = await APP.get.public_workspace(id);
  console.log("App loaded:", response);
  if(response?.data?.notFound){
    window.location.href = `${MAIN_APP_URL}/workspace/new/${response?.data?.workspace}`
  }
  let isdefault = false;
  if (id === "www" || id === "localhost" || id === "help-desk") {
     isdefault = true;
  }

  let info = response.data;

  window.title = info.page_title;

  let payload = {
    name: info.public_name,
    show_new_tennant: isdefault,
    colors: {
      main: info.main_color,
      secondary: info.secondary_color,
      accent: info.action_color,
      text: info.font_color,
      title: info.main_color,
      headerText: info.page_title_color,
    },
    title: info.page_title,
    logo: info.logo,
    darklogo: info.darklogo,
    favicon: info.favicon,
    website: info.website,
    remove_logo: info.remove_logo,
  };

  console.log("PAYLOAD:", payload);

  return payload;
});

const appSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoadingApp: (state) => {
      state.loading = true;
    },

    reloadApp: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadApp.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadApp.fulfilled, (state, action) => {
        console.log("FULLFILLED", action);
        state.app = action.payload;
        state.loading = false;
      })
      .addCase(loadApp.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setApp, reloadApp, setLoadingApp } = appSlice.actions;

export default appSlice.reducer;
