import React from "react";

import powered from "../../assets/helpdesk/powered.png";
import { useSelector } from "react-redux";

function PoweredLink() {
  const { remove_logo } = useSelector((state) => state.appReducer?.app);
  if (remove_logo) return null;
  return (
    <a href="https://help-desk.app" target="_blank" rel="noreferrer" style={{cursor: 'pointer'}}>
      <img src={powered} style={{ height: 30, marginTop: 30 }} />
    </a>
  );
}

export default PoweredLink;
