import React from "react";


import * as S from "../styles";

import Or from "../../../components/Or";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import mainlogo from "../../../assets/helpdesk/logo-small.png";

import powered from "../../../assets/helpdesk/powered.png";
import { Logo, Link, Input, FH, Text } from "../../main-styles";
import PoweredLink from "../../../components/PoweredLink";

function Validate() {
  const { appReducer } = useSelector((state) => state);
  const [agreed, setAgreed] = React.useState(false);
  const { app } = appReducer;
  console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;
  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Text>Clique no botão para validar seu email</Text>
        <Button>Validar meu email</Button>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default Validate;
