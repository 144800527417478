import styled from 'styled-components';

export const Container = styled.div`
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    width: 100%;
    font-size: 24px;
    padding: 10px;
    gap: 10px;
`;
