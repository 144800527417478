import React from 'react';
import * as S from './styles'
import { Button } from '../main-styles';
const Pricing = () => {
    return (
        <S.Container id="pricing">
            <h1>Precificação</h1>
            <div className="pricing-table">
                <div className="column">
                    <h2>Gratuito</h2>
                    <p>Até 4 agentes</p>
                    <Button>Escolher</Button>
                </div>
                <div className="column">
                    <h2>R$12 por agente</h2>
                    <p>Simples assim</p>
                    <Button>Escolher</Button>
                </div>
                <div className="column">
                    <h2>Enterprise</h2>
                    <p>Muitos agentes?</p>
                    <Button>Contato</Button>
                </div>
            </div>
        </S.Container>
    );
};

export default Pricing;