import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

// Import your reducers here

// Configure Redux Persist
const persistConfig = {
    key: 'root',
    storage,
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
    reducer: persistedReducer,
});

// Create the Redux Persistor
const persistor = persistStore(store);

export { store, persistor };