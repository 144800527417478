export const isDev = process.env.NODE_ENV === 'development';

export const API_URL = process.env.REACT_APP_API_URL

export const MAIN_APP_URL = isDev ? 'http://localhost:3000' : 'https://help-desk.app';

export const REQ_STATUS = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error',
    LOADING: 'loading',
    SENDING: 'sending',
    RELOADING: 'reloading',
    IDLE: 'idle',
};