import styled from "styled-components";

import { store } from "../redux/configureStore";

export const Text = styled.div`
  color: #5e5e5e;
  font-size: 15px;
`;

export const Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  
`;
export const Subtitle = styled(Text)``;
export const Description = styled(Text)``;
export const Label = styled(Text)``;
export const Link = styled(Text)`
  cursor: pointer;
  color: ${(props) => props?.color};
  text-decoration: underline;
`;

export const Logo = styled.img`
  height: ${(props) => props.height || 60}px;
  width: auto;
  object-fit: contain;
`;

export const Button = styled.button`
  background: ${(props) =>
    props?.backgroundColor ? props?.backgroundColor : "#4b50d8"};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background: #fbfbfc;
  max-width: 100%;
  width: 100%;
  padding: 15px;
  color: #333;
  font-size: 15px;
  box-sizing: border-box;
`;

export const FH = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
