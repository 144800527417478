import React from "react";


import * as S from "../styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import { NavLink } from "react-router-dom";
import PoweredLink from "../../../components/PoweredLink";
import { FH, Input, Link, Logo, Text } from "../../main-styles";

function SignUp() {
  const { appReducer } = useSelector((state) => state);
  const [agreed, setAgreed] = React.useState(false);
  const { app } = appReducer;
  console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;
  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Input type="text" placeholder="Nome completo" autoComplete="full-name" />
        <Input type="text" placeholder="Email" autoComplete="email" />
        <Input type="password" placeholder="Senha" autoComplete="new-password" />
        <Input type="password" placeholder="Confirmação de senha" />
        <FH>
          <input
            type="checkbox"
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
          />
          <Text style={{ marginLeft: 10, textAlign: 'left' }}>
            Concordo com os termos de uso e política de privacidade
          </Text>
        </FH>
        <Button>Criar conta</Button>
        <Link color={colors?.main}>Esqueci minha senha</Link>
        <Or />
        <Text>Já tem uma conta? <NavLink to="/auth/sign-in" style={{color: colors?.main}}>clique aqui</NavLink></Text>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default SignUp;
