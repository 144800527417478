import React, { useEffect, useState } from "react";

import * as S from "../../auth/styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import { NavLink, useParams } from "react-router-dom";
import PoweredLink from "../../../components/PoweredLink";
import { FH, Input, Link, Logo, Text, Title } from "../../main-styles";
import { REQ_STATUS } from "../../../util/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckSquare,
  faMagnifyingGlass,
  faSpinner,
  faSquare,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { APP } from "../../../api/APP";
import toast from "react-hot-toast";
import { validateEmail } from "../../../util/functions";

function NewWorkspace() {
  const { ws } = useParams();
  const { appReducer } = useSelector((state) => state);
  const [agreed, setAgreed] = React.useState(false);
  const [state, setState] = useState({
    workspace: ws || "",
    workspace_loading: false,
    workspace_valid: false,
    workspace_validated: false,
    email: "",
    password: "",
    confirmPassword: "",
    status: REQ_STATUS.LOADING,
  });
  const {
    workspace,
    email,
    password,
    confirmPassword,
    status,
    workspace_loading,
    workspace_valid,
    workspace_validated,
  } = state;
  const { app } = appReducer;
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;

  useEffect(() => {
    // focus on the first input
    document.getElementById("workspace").focus();
    // title
    let windowtitle = "Help-Desk.app - Novo workspace";
    document.title = windowtitle;
  }, []);

  async function verifyWorkspace() {
    if (workspace_validated || workspace_loading) return;
    if (workspace.length < 3) {
      toast.error("O nome do workspace deve ter no mínimo 3 caracteres");
      setState((s) => ({
        ...s,
        workspace_loading: false,
        workspace_validated: true,
        workspace_valid: false,
        status: REQ_STATUS.IDLE,
      }));
      return;
    }
    setState((s) => ({
      ...s,
      workspace_loading: true,
      workspace_validated: false,
      workspace_valid: false,
    }));
    let available = await APP.get.check(workspace);
    console.log("AVAILABLE / workspace_valid", available);
    if (!available) {
      toast.error("Já existe um workspace com esse nome");
    }
    setState((s) => ({
      ...s,
      workspace_loading: false,
      workspace_validated: true,
      workspace_valid: available,
      status: REQ_STATUS.IDLE,
    }));
  }

  async function validate() {
    if (status !== REQ_STATUS.IDLE) return;
    if (!workspace_valid) {
      toast.error("Escolha um worskpace válido");
      return;
    }
    if(!agreed){
      toast.error("Você precisa concordar com os termos de uso e política de privacidade");
      return;
    }
    if(password.length < 6){
      toast.error("A senha deve ter no mínimo 6 caracteres");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("As senhas não conferem");
      return;
    }
    if(!validateEmail(email)){
      toast.error("Email inválido");
      return;
    }
  }

  async function createWS() {
    setState((s) => ({ ...s, status: REQ_STATUS.PENDING }));
  }

  let icon;
  if (workspace_loading || workspace_validated) {
    icon = workspace_loading ? faSpinner : workspace_valid ? faCheck : faTimes;
  }

  return (
    <S.WorkspaceContainer backgroundColor={backgroundColor}>
      <S.WorkspaceWhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Title style={{ marginTop: 30 }}>Novo workspace</Title>
        <Text style={{ textAlign: "left", width: "100%", marginTop: 20 }}>
          Vamos configurar o workspace de sua empresa
        </Text>
        <FH>
          <Text>https://</Text>
          <Input
            id="workspace"
            type="text"
            placeholder="sua_empresa"
            value={workspace}
            onBlur={verifyWorkspace}
            onChange={(e) =>
              setState({
                ...state,
                workspace: e.target.value,
                workspace_valid: false,
                workspace_validated: false,
                workspace_loading: false,
              })
            }
            style={{
              maxWidth: 150,
              border: 0,
              height: 20,
              borderBottom: "1px solid #ccc",
              borderRadius: 0,
            }}
          />
          <Text>.help-desk.app</Text>
          {icon && (
            <div style={{ display: "inline-block" }}>
              <FontAwesomeIcon
                icon={icon}
                style={{
                  marginLeft: 10,
                  color: workspace_loading
                    ? "#ccc"
                    : workspace_valid && workspace_validated
                    ? "#ccc"
                    : "#e74009",
                }}
                spin={workspace_loading ? true : false}
              />
            </div>
          )}
        </FH>

        <Input
          type="text"
          placeholder="Email"
          autoComplete="email"
          value={email}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          style={{ marginTop: 30 }}
        />
        <Input
          type="password"
          placeholder="Senha"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setState({ ...state, password: e.target.value })}
        />
        <Input
          type="password"
          placeholder="Confirmação de senha"
          value={confirmPassword}
          onChange={(e) =>
            setState({ ...state, confirmPassword: e.target.value })
          }
        />
        <FH onClick={() => setAgreed(!agreed)}>
          <FontAwesomeIcon icon={agreed ? faCheckSquare : faSquare} />

          <Text style={{ marginLeft: 10, textAlign: "left" }}>
            Concordo com os termos de uso e política de privacidade
          </Text>
        </FH>
        <Button onClick={validate}>Criar conta</Button>
        <Link color={colors?.main}>Esqueci minha senha</Link>
        <Or />
        <Text>
          Já tem uma conta?{" "}
          <NavLink to="/auth/sign-in" style={{ color: colors?.main }}>
            clique aqui
          </NavLink>
        </Text>
      </S.WorkspaceWhiteContainer>
    </S.WorkspaceContainer>
  );
}

export default NewWorkspace;
