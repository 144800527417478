import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CTA = styled.div`
  background-color: #f8f8f8;
  padding: 30px;
  margin: 35px;
  display: flex;
  gap: 25px;
  text-align: right;
  font-size: 1.2em;
  border-radius: 25px;
  // flex when on mobile
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const FAQContainer = styled.div`
  padding: 30px;
  margin: 20px;
  > h1 {
    font-size: 1.3em;
    color: #333;
    margin-bottom: 10px;
  }
  > ul {
    list-style-type: none;
    padding: 0;
    > li {
      margin-bottom: 10px;
      > h2 {
        font-size: 1em;
        color: #333;
        margin-bottom: 10px;
      }
      > p {
        font-size: 1em;
        color: #666;
        margin-bottom: 10px;
      }
    }
  }
`;

export const WhatWeDoContainer = styled.div`
  background-color: #e0e0e0;
  padding: 30px;
  margin: 20px;
  border-radius: 25px;
  color: #333;
  line-height: 1;
  background-image: linear-gradient(5deg, #e0e0e0 0%, #f8f8f8 74%);

  > h1 {
    font-size: 1.9em;
    color: #333;
    margin-bottom: 10px;
  }
  > p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 10px;
  }
`;

export const CenteredContainer = styled.div`
  grid-area: 1 / 2 / 2 / 12;
  @media (max-width: 768px) {
    grid-area: 1 / 1 / 2 / 13;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  flex-wrap: wrap;
  gap: 20px;
  // make the borders of the nav visible
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
  @media (max-width: 768px) {
    flex: 1;
  }
`;
export const Logo = styled.img`
  width: auto;
  height: 50px;
`;

export const Options = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 15px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex: 1;
  gap: 10px;
  @media (max-width: 768px) {
    margin-top: 10px;
    flex: 1;
  }
`;

export const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 20px;
  margin: 50px;
  //make it 1 column on mobile
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const HeroTitle = styled.h1`
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
`;

export const HeroSubtitle = styled.h2`
  font-size: 1.5em;
  color: #666;
  margin-bottom: 10px;
`;

export const HeroImage = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 5%;
`;

export const HeroLeft = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  // make it 1 column on mobile
  @media (max-width: 768px) {
    grid-area: 1 / 1 / 2 / 2;
    align-items: center;
  }
`;

export const HeroRight = styled.div`
  grid-area: 1 / 3 / 2 / 4;
  // make it 1 column on mobile
  @media (max-width: 768px) {
    grid-area: 2 / 1 / 3 / 2;
  }
`;
