import React from "react";


import * as S from "../styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import { NavLink } from "react-router-dom";
import PoweredLink from "../../../components/PoweredLink";
import { Input, Link, Logo } from "../../main-styles";

function SignIn() {
  const { appReducer } = useSelector((state) => state);
  const { app } = appReducer;
  console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;
  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Input type="text" placeholder="Email" autoComplete="email" />
        <Input type="password" placeholder="Senha" autoComplete="password" />
        <Button>Acessar</Button>
        <Link color={colors?.main}>Esqueci minha senha</Link>
        <Or />
        <NavLink to="/auth/sign-up" style={{width: '100%'}}>
          <Button color style={{width: '100%'}}>Crie sua conta</Button>
        </NavLink>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default SignIn;
