import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader";
import { loadApp } from "../redux/reducers/app";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import Validate from "./auth/Validate";
import ChangePassword from "./auth/ChangePassword";
import NewWorkspace from "./workspace/NewWorkspace";
import KnowledgeBase from "./KnowledgeBase";
import Comercial from "./comercial";
import Pricing from "./pricing";

export default function App() {
  const { authReducer } = useSelector((state) => state);
  // const {  } = authReducer
  const { appReducer } = useSelector((state) => state);
  const { loading, app, isAuthenticated } = appReducer;
  console.log("APP", app)
  const { show_new_tennant } = app;
  const dispatch = useDispatch();
  useEffect(() => {
    init();
  }, []);

  async function init() {
    dispatch(loadApp());
  }

  useEffect(() => {
    console.log("AppReducer:", appReducer);
    window.title = app?.title;
  }, [appReducer]);
  console.log("Current App:", app);
  console.log("authReducer:", authReducer);

  const isTennant = app?.name !== "Help-Desk.app";

  if (loading) return <FullScreenLoader />;

  return (
    <Router>
      <Routes>
        {show_new_tennant && !isAuthenticated && (
          <>
            <Route path="/workspace/new" element={<NewWorkspace />} />
            <Route path="/workspace/new/:ws" element={<NewWorkspace />} />
          </>
        )}
        {/* UNAUTHENTICATED ROUTES */}
        {(isAuthenticated || isTennant)  ? (
          <Route path="/" element={<KnowledgeBase />} />
        ) : (
          <>
          <Route path="/" element={<Comercial />} />
          </>
        )}
        <>
          <Route path="/" element={<KnowledgeBase />} />
          <Route path="/auth/sign-in" element={<SignIn />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/auth/sign-up" element={<SignUp />} />
          <Route path="/auth/validate/:uuid" element={<Validate />} />
          <Route path="/auth/reset-password" element={<ChangePassword />} />
          <Route path="/knowledge-base" element={<KnowledgeBase />} />
        </>
        <Route path="/about"></Route>
        <Route path="/users"></Route>
        <Route path="/"></Route>
      </Routes>
    </Router>
  );
}
