import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Loader = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spinAnimation} 1s linear infinite;
`;

const FullScreenLoader = () => {
    return (
        <LoaderContainer>
            <Loader />
        </LoaderContainer>
    );
};

export default FullScreenLoader;