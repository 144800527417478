import React, { useState } from "react";

import * as S from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-solid-svg-icons";

function FAQ() {
  return (
    <S.FAQContainer>
      <h1>Perguntas Frequentes</h1>
      <ul>
        <Question
          question="O que é um sistema de help desk"
          answer="Um sistema de help desk é uma ferramenta que permite que as empresas gerenciem e respondam a solicitações de suporte de seus clientes."
        />
        <Question
          question="Como é a precificação"
          answer="Você pagará uma taxa mensal fixa com base no número de agentes que você tem em sua equipe de suporte."
        />
        <Question
          question="O que é um agente?"
          answer="Um agente é um membro da equipe de suporte que pode responder a solicitações de suporte."
        />
        <Question
          question="Posso testar o Help-Desk.app antes de comprar?"
          answer="Sim, você pode testar o Help-Desk.app gratuitamente por 14 dias."
        />
      </ul>
    </S.FAQContainer>
  );
}

function Question({ question, answer }) {
  const [open, setOpen] = useState(false);
  return (
    <li onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
      <h2>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={open ? faAngleDown : faAngleRight}
          style={{ marginRight: 10 }}
        />
        {question}
      </h2>
      {open && <p style={{ marginLeft: 30 }}>{answer}</p>}
    </li>
  );
}

export default FAQ;
