import React from "react";
import * as S from "../styles";

function WhatWeDo() {
  return (
    <S.WhatWeDoContainer>
      <h1>O que fazemos?</h1>
      <p>
        Somos um helpdesk de baixo custo para empresas que desejam fornecer um
        excelente suporte ao cliente sem gastar uma fortuna em um sistema de
        suporte.
      </p>
    </S.WhatWeDoContainer>
  );
}

export default WhatWeDo;
