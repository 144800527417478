import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { persistor, store } from "./redux/configureStore";
import Routes from "./routes";
import { Toaster } from "react-hot-toast";
import OneSignal from "react-onesignal";
import { useEffect, useState } from "react";
import "animate.css";

function App() {
  useEffect(() => {
    runOneSignal();
  }, []);

  async function runOneSignal() {
    await OneSignal.init({
      appId: "3adf0b2f-c9e0-42b2-a081-d0d090126773",
      allowLocalhostAsSecureOrigin: true,
    });
    OneSignal.Slidedown.promptPush();
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
      <Toaster position="top-right" reverseOrder={false} />
    </Provider>
  );
}

export default App;
