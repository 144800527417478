import axios from "axios";
import { API_URL } from "../../util/constants";

export const APP = {
  get: {
    all: async () => {},
    public_workspace: async(workspace) =>{
      let response =await axios.get(`${API_URL}/v1/tennants/public/${workspace}`);
   
      return response;
    },
    one: async (id) => {
      return new Promise((resolve, reject) => {
        console.log("BUSCOU APP", id);
        setTimeout(() => {
          if (id === "seidecor") {
            resolve({
              name: "Seidecor",
              colors: {
                main: "#be2581",
                secondary: "#555",
                accent: "#777",
                text: "#fff",
                title: "#be2581",
                headerText: "#ffffff"
              },
              logo: "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png",
              darklogo: "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png",
              favicon: "https://intercom.help/sei-de-cor/assets/favicon",
              website: "https://app.seidecor.com.br/#/v2",
              title: "Painel de Suporte Sei de Cor"
            });
          } else {
            resolve({
              name: "Default App",
              colors: {
                main: "#333",
                secondary: "#555",
                accent: "#777",
                text: "#fff",
                title: "#555",
                headerText: "#ffffff"
              },
              logo: "/assets/logo.png",
              favicon: "https://via.placeholder.com/32",
              website: "https://example.com",
              title: "Help-Desk App"
            });
          }
        }, 1000);
      });
    },
    check: async (workspace) => {
      if(!workspace) return false;
      let response =await axios.get(`${API_URL}/v1/tennants/public/${workspace}`);
      return response?.data?.notFound ? true : false;
    },
  },
  set: {
    new: async (data) => {},
    update: async (id, data) => {},
    delete: async (id) => {},
    activate: async (id) => {},
  },
};
