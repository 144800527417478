import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
gap: 10px;
`;

export const Line = styled.div`
    border-bottom: 1px solid #ECECEC;
    flex: 1;
    margin-bottom: 7px;
`;
export const Text = styled.div`
  color: #ECECEC;
`;
